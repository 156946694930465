
export class CommonConstants {
    public static uiVersion: string = "28.0.0";
    public static IN_HOME_COLOR: string = "#ac547d";
    public static TELEHEALTH_COLOR: string = "#578cb6";
    public static TELEPHONE_COLOR: string = "#dfc48a";
    public static LIGHTGRAY: string = "#787373";

    public static NEW_IN_HOME_COLOR: string = "#880015";
    public static NEW_TELEHEALTH_COLOR: string = "#2196f3";
    public static NEW_TELEPHONE_COLOR: string = "#ffc241";
    public static NEW_IN_CLINIC_COLOR: string = "#7FFFD4";

    public static IN_HOME: string = "in-home";
    public static TELEHEALTH: string = "telehealth";
    public static TELEPHONE: string = "phone";
    public static IN_CLINIC: string = "in-clinic";

    public static DEFAULT_HOME: string = "calendar";
    public static CENTRAL_ASSESSMENT_NURSE: string = "Central Assessment Nurse";

    public static readonly ACCOUNT_NUMBER : string = 'Somatus POP HEALTH #30160';


    public static STATUS_CANCEL: string = "Cancelled";
    public static APPOINTMENT_CANCEL: string = "CANCELLED";
    public static readonly LS_USERNAME: string = "USERNAME";
    public static readonly LS_USEROLE: string = "USEROLE";
    public static readonly LS_NAME: string = "NAME";
    public static readonly LS_SESSION : string = 'SESSION_ID';


}

export enum CommonConstantEnum {
    STATUS_CANCEL = 'Cancelled',
    APPOINTMENT_CANCEL = 'CANCELLED'

}
