import { Component,NgZone, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GenericModalService } from 'src/app/commons-ui/generic-modal/generic-modal-service';
import { AppointmentPopupComponent, AppointmentPopupInput, AppointmentPopupOutput } from '../appointment-popup/appointment-popup.component';
import { CalendarEvent } from './calendar-event';
import { Loader } from '../../commons-ui/loader';
import { AddCareMemberComponent } from '../add-care-member/add-care-member.component';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
momentTimezone.moment = moment;

import {
    MbscCalendarEvent,
    MbscEventcalendar,
    MbscEventcalendarOptions,
    MbscResource,
    setOptions,
    MbscEventcalendarView,
    MbscCellClickEvent,
    MbscPageChangeEvent,
    momentTimezone
    
} from '@mobiscroll/angular';
import { DateTime } from 'src/app/commons/date-time';
import { EventHelper } from './event-helper';
import { ReferenceDataProvider } from 'src/app/commons/reference-data-provider';
import { SelectedTask } from '../task-list/selected-task';
import { Router } from '@angular/router';
import { ApptModePipe } from 'src/app/commons/pipes/appt-mode.pipe';
import { ViewSchedulePopupComponent, ViewSchedulePopupInput } from '../view-schedule-popup/view-schedule-popup.component';
import { ScheduleService } from '../services/schedule.service';
import { CommonConstants } from '../../commons/common-constants';
import { HstimeZoneMasterModel } from '../../api-client/model/hstimeZoneMasterModel';
import { PatientContactInfo } from '../../api-client/model/patientContactInfo';
import { AppointmentDetail } from '../../api-client/model/appointmentDetail';
import { PatientModel } from '../../api-client/model/patientModel';
import { CareMemberScheduleResponse } from '../../api-client/model/careMemberScheduleResponse';
import { AppointmentService } from '../../api-client/api/appointment.service';
import { CareMemberCalenderEvents } from '../../api-client/model/careMemberCalenderEvents';
import { CareMemberScheduleModel } from '../../api-client/model/careMemberScheduleModel';
import { CreateAppointmentRequest } from '../../api-client/model/createAppointmentRequest';
import { AppointmentResponseModel } from '../../api-client/model/appointmentResponseModel';
import { ValueTupleOfStringAndLong } from '../../api-client/model/valueTupleOfStringAndLong';
import { CareTeamService } from '../../api-client/api/careTeam.service';
import { HSCalendarService } from '../../api-client/api/hSCalendar.service';
import { CareMemberInfo } from '../../api-client/model/careMemberInfo';
import { AddCareMemberAndEventSubscribe } from 'src/app/models/common';
import { CalenderEventExt, UserService } from 'src/app/api-client';
import { LoggingServiceTsService } from 'src/app/store/loggingService/logging.service';

declare const $: any;


setOptions({
    theme: 'ios',
    themeVariant: 'light'
});

@Component({
    selector: 'hs-schedule-calendar',
    templateUrl: './schedule-calendar.component.html',
    styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarComponent implements OnInit, OnDestroy {
    public momentPlugin = momentTimezone;
    public centralCareNurse: CareMemberInfo[] = [];
    private startTimeOfTheDay: string = "00:00";
    private endTimeOfTheDay: string = "24:00";
    private startTimeOfTheDayModal: string = "06:00";
    private endTimeOfTheDayModal: string = "24:00";
    public timezoneOffset: number = 0;
    private draftAppointmentId: number = 0;
    private meetingLink: string | null= null;

    public selectedMonthDate : Date = DateTime.now().startOfTheDay().getDate();;
    public isMonthCreateAppointment? : boolean = true;

    public popupCalView = 'week';
    public calView: MbscEventcalendarView = {
        schedule: { type: 'week',
        startTime: this.startTimeOfTheDayModal,
        endTime: this.endTimeOfTheDayModal
     }
    };
    public eventsPopupCal: MbscCalendarEvent[] = [];
    public SelectedCareMemberId?: number;
    public SelectedCareMemberName?: string;
    public popupCalEventsLoading : boolean = false;

    public timezones: HstimeZoneMasterModel[] = [];

    public timezone?: string;
    public bestTimeElasticId?: string;
    public selectedMonth: any;
    public minDate: any;
    public debounceTimer: any;
    public methodHit : boolean = false;
    

    @Input()
    public selectedTasks: SelectedTask[] = [];
    @Input()
    public address?: string;
    @Input()
    public addressType?: string;
    @Input()
    public contactInfo?: PatientContactInfo;
    
    @Input()
    public appointmentId?: number;

    public appointmentDetails?: AppointmentDetail;

    @ViewChild('scheduleTimeline', { static: false })
    scheduleTimeline?: MbscEventcalendar;

    calendarSelectedDate: any;

    calendarOptions: MbscEventcalendarOptions = {};
    calendarOptionsPopupCal: MbscEventcalendarOptions = {};

    public resources: MbscResource[] = [];
    public events: CalendarEvent[] = [];

    public careMembersMap: Map<any, CareMemberInfo> = new Map();
    public updatedEvents: CalendarEvent[] = [];
    public testVideoLinkRequired: boolean = false;
    public nPSearchLink: boolean = false;
    
    public patientModel?: PatientModel;

    public schedulerVisible: boolean = true;
    public careTeamMembersdetails : CareMemberInfo[] = [];
    public scheduleResponses: CareMemberScheduleResponse[] = [];
    public showError : boolean = false;
    public showErrorEmail : boolean = false;
    public showErrorPhone : boolean = false;
    public notificationEmail?: string;
    public notificationMobile?: string;
    public myColors :any= [];
    private removeCareMemberObj?: CareMemberInfo;
    private deleteCareMemberInProgress: boolean = false;
    private firstTimeLoad: boolean = false ;
    private userName : string = '';
    constructor(private router: Router,
                private toastr: ToastrService,
                private referenceDataProvider: ReferenceDataProvider,
                private genericModalService: GenericModalService,
                private careTeamService: CareTeamService,
                private appointmentService: AppointmentService,
                private calendarService: HSCalendarService,
                private eventHelper: EventHelper,
                private apptModePipe: ApptModePipe,
                public scheduleService: ScheduleService,
                public zone: NgZone,
                private logging: LoggingServiceTsService,
                private readonly userService : UserService) { }

    async ngOnInit(): Promise<void> {
        this.logging.logEventForComponentRendering('Schedule Grid : Scheduling Calendar Component');
        let session = this.logging.GetSessionDetails();
        this.logging.logPageView("Schedule Page",window.location.href,session);
        this.userName = await this.userService.userGetUserName().toPromise() ?? '';
        let dateTime = new Date().getTime();
        let today: Date = DateTime.now().startOfTheDay().getDate();
        this.minDate = today;
        this.selectedMonth = today;
        this.calendarSelectedDate = today;
        this.selectedMonthDate = today;
        this.firstTimeLoad = true;

        const me = this;
        this.calendarOptions = {
            
            theme: 'ios',
            themeVariant: 'light',
            clickToCreate: 'double',
            dragToCreate: false,
            dragToMove: false,
            dragToResize: false,
            dataTimezone: 'US/Eastern',
            min: today,
            dateFormat: "MM/DD/YYYY",
            view: {
                timeline: {
                    type: 'day',
                    startTime: this.startTimeOfTheDay,
                    endTime: this.endTimeOfTheDay
                }
            },
            onCellClick: (args: MbscCellClickEvent) => {
                let clickedDate: Date = new Date(args.date);
                let maxValidDate: Date = new Date(me.patientModel?.eligibilityEndDate!);
                if(clickedDate > maxValidDate) {
                    me.toastr.warning("Appointment cannot be scheduled after member eligibility end date!");
                    return;
                }



                let resource: MbscResource | undefined = me.resources.find(resource => resource.id == args.resource);
                if(resource?.loading) {
                    me.toastr.warning("The calendar is still loading for the Care Member.");
                    return;
                }
                if(resource?.disabled) {
                    me.toastr.warning("Can't schedule with " + resource.name + " as their Calendar is not available");
                    return;
                }

                /** Identify timezone - differance from the current selected timezone */
                let event: CalendarEvent = {                                                                                                          
                    id: Date.now(),
                    start: clickedDate,
                    patientId: this.patientModel?.id,
                    resource: args.resource,
                    text: "New appoinement",
                    isHSSchedule: true,
                    timezoneOffset: this.timezoneOffset 
                };  
                this.eventHelper.updateColor(event);
                this.createAppointment(event, true);
                this.logging.trackEvent('Schedule Grid : User Clicked on Schedulling Grid', this.logging.createCustomLog('Clicked on  CareMember Calendar', 'Sucessfully Opened Pop up for Appointment Creation', 'INFO'));
            },
            onPageChange: (args: MbscPageChangeEvent) => {
                this.clicked();
                let that = this;
                dateTime = new Date().getTime();
                setTimeout(function () {
                    let currentTime = new Date().getTime();
                    let difference = currentTime - dateTime;
                    if (difference > 2000) {
                        that.loadAllEvents();
                    }
                }, 2000)
                this.selectedMonth = args.viewStart;
                this.logging.trackEvent('Schedule Grid : User Clicked on Navigation Key', this.logging.createCustomLog('On Click loaded CareMember Events', 'Sucessfully Navigated', 'INFO'));

            },
            onEventClick: (args) => {
                let tempDate = args.date;
                let tzDate = new Date(tempDate.toLocaleString('en-US', { timeZone: this.timezone }));
                this.timezoneOffset = tempDate.getTime() - tzDate.getTime();

                if(!args.event.isHSSchedule) {
                    return;
                }

                if(args.event.appointmentId && me.appointmentDetails?.id != args.event.appointmentId) {
                    this.genericModalService.showModel<ViewSchedulePopupInput, void>(
                        ViewSchedulePopupComponent,
                        {
                            event: args.event,
                            timezoneOffset:this.timezoneOffset
                        }
                    );
                    return;
                }

                this.eventHelper.updateColor(args.event);
                this.createAppointment(args.event, false);
            },
            onEventCreated: (args) => {
                console.log("onEventCreated", args);
            },
            onEventDeleted: (args) => {
                console.log("onEventDeleted", args);
            },
            onEventUpdated: (args) => {
                console.log("onEventUpdated", args);
            }
        };

        this.calendarOptionsPopupCal = {
            theme: 'ios',
            themeVariant: 'light',
            dragToCreate: false,
            dragToMove: false,
            dragToResize: false,
            dataTimezone: 'US/Eastern',
            min: today,
            dateFormat: "MM/DD/YYYY",
            onPageChange: (args: MbscPageChangeEvent) => {
                this.loadCareMemberEventsForPopupCal(this.SelectedCareMemberId as number, args.firstDay, args.lastDay);
              },
              onCellClick: (args: MbscCellClickEvent) => {
                console.log('onCellClick::::', args.date);
                let clickedDate: Date = new Date(args.date);

                if(clickedDate < today)
                {
                    this.isMonthCreateAppointment = false;
                    return;
                }

                let maxValidDate: Date = new Date(me.patientModel?.eligibilityEndDate!);
                if(clickedDate > maxValidDate) {
                    me.toastr.warning("Appointment can not be booked after patient eligibility end date!");
                    return;
                }

                let resource: MbscResource | undefined = me.resources.find(resource => resource.id == this.SelectedCareMemberId);
                if(this.popupCalEventsLoading) {
                    me.toastr.warning("The calendar is still loading for the Care Member.");
                    return;
                }
                if(resource?.disabled) {
                    me.toastr.warning("Can't schedule with " + resource.name + " as their Calendar is not available");
                    return;
                }
                if(this.popupCalView === "month")
                {
                    this.selectedMonthDate = clickedDate;
                    this.isMonthCreateAppointment = true;
                    return;
                }
                this.isMonthCreateAppointment = false;
                /** Identify timezone - differance from the current selected timezone */
                let event: CalendarEvent = {                                                                                                          
                    id: Date.now(),
                    start: clickedDate,
                    patientId: this.patientModel?.id,
                    resource: this.SelectedCareMemberId,
                    text: "New appoinement",
                    isHSSchedule: true,
                    timezoneOffset: this.timezoneOffset 
                };
                this.eventHelper.updateColor(event);
                $("body").toggleClass("is-asidemodel");
                this.calendarSelectedDate = clickedDate;
                this.reloadGrid(); 
                this.createAppointment(event, true);
            },
              onEventClick: (args) => {
                let tempDate = args.date;
                let tzDate = new Date(tempDate.toLocaleString('en-US', { timeZone: this.timezone }));
                this.timezoneOffset = tempDate.getTime() - tzDate.getTime();
          
                if(!args.event.isHSSchedule) {
                    return;
                }
                if(args.event.appointmentId && me.appointmentDetails?.id != args.event.appointmentId) {
                    this.genericModalService.showModel<ViewSchedulePopupInput, void>(
                        ViewSchedulePopupComponent,
                        {
                            event: { ...args.event, ...args.event.careMemberSchedule },
                            timezoneOffset: this.timezoneOffset,
                            showOptionalButtons: true,
                        }
                    );
                return;
                }
                this.eventHelper.updateColor(args.event);
                $("body").toggleClass("is-asidemodel");
                this.createAppointment(args.event, false);
              },
            onEventCreated: (args) => {
                console.log("onEventCreated", args);
            },
            onEventDeleted: (args) => {
                console.log("onEventDeleted", args);
            },
            onEventUpdated: (args) => {
                console.log("onEventUpdated", args);
            }
        };

        this.loadTimeZones();
        this.scheduleService.openCareMemberEventsSubscription().subscribe((response: CareMemberInfo) => {
            if (response) {
                let event: CalendarEvent = {                                                                                                       
                    id: Date.now(),
                    start: response.scheduleStartDate,
                    patientId: this.patientModel?.id,
                    resource: response?.id,
                    text: "New appoinement",
                    isHSSchedule: true,
                    timezoneOffset: this.timezoneOffset,
                    end: response.scheduleEndDate
                };
                this.eventHelper.updateColor(event);
                this.createAppointment(event, true);
            }
        })
        this.scheduleService.addCareMemberEventsSubscription().subscribe(async (response: AddCareMemberAndEventSubscribe) => {          
            Loader.showLoader("schedule-timeline");
            if (response.careMember.length > 0) {
                this.selectedMonth = new Date(response.careMember[0].scheduleStartDate);
                this.calendarSelectedDate = this.selectedMonth;
                for(let caremember of response.careMember) {
                    await this.addCareMemberAppointmentWizard(caremember);
                } 
                await this.loadAllEventsAppointementWizard();
                this.reloadGrid();
            }            
            const _this = this;
            setTimeout(() => {
                if (response.calendarEvent.length > 0) {
                    for(let event of response.calendarEvent){ 
                    event.text = this.apptModePipe.transform(event?.modeId);
                    _this.eventHelper.updateColor(event);
                    _this.updateOrAdd(_this.events, event);
                    _this.updateOrAdd(_this.updatedEvents, event);
                    }
                }
                Loader.hideLoader("schedule-timeline");
            }, 1000)
            this.bestTimeElasticId = response.elasticId;
        })
    }
    private async loadTimeZones(): Promise<void> {
        this.timezones = await this.referenceDataProvider.getTimezones();
        this.timezone = this.timezone ?? "US/Eastern";
    }



    @Input()
    public set appointment(appt: AppointmentDetail) {
        if(!appt){
            return;
        }
        this.appointmentDetails = appt;
        this.timezone = this.appointmentDetails?.timezone;
        this.timezoneChanged();

        this.appointmentDetails?.appointmentScheduleDetails?.forEach(careMember => {
            let memberIsCentralCareNurse = careMember.type == CommonConstants.CENTRAL_ASSESSMENT_NURSE;
            this.addCareMemberAndEvents(careMember,false, memberIsCentralCareNurse,true);
        });
      
        const selectedDate: Date = new Date( this.appointmentDetails?.appointmentScheduleDetails?.[0].scheduleStartDate + "Z"!)
        
        /** Convert time to selected timezone and select calendar date */
        this.calendarSelectedDate = DateTime.from(selectedDate).addMinutes(DateTime.getLocalOffset(this.timezone!)).startOfTheDay().getDate();

        this.reloadGrid();
    }

    @Input()
    public set patient(patient: PatientModel) {
        this.patientModel = patient;
        /** Set Patient Timezone, if not availble then set default EST */
        this.timezone =  this.appointmentDetails?.timezone ?? patient?.timeZone ?? "US/Eastern";
        if(this.patientModel?.id) {
            this.loadCareTeamMembers();
            /** Initialize Timezone Offset */
            this.timezoneChanged();
            /* enable/disable NP search link */
            this.showHideNPSearchLink();
        }
    }
    @Input() 
    public set addCentralCareNurse(careNurse: CareMemberInfo[]) {
        if (careNurse && careNurse.length > 0) {
            for (const element of careNurse) {
                let careMember: CareMemberInfo = element;
                this.addCareMemberAndEvents(careMember,false, true);
            }
            const selectedDate: Date = new Date(careNurse?.[0].scheduleStartDate)

            /** Convert time to selected timezone and select calendar date */
            this.calendarSelectedDate = DateTime.from(selectedDate).addMinutes(DateTime.getLocalOffset(this.timezone!)).startOfTheDay().getDate();
            this.reloadGrid();

        }
    }
    private async loadCareTeamMembers(): Promise<void> {
        Loader.showLoader("schedule-timeline");
        try {
            let resultCT = await this.careTeamService.careTeamGetCareMemberByPatientId(this.patientModel?.id!).toPromise();
            if(resultCT != undefined){
                this.careTeamMembersdetails = resultCT;
            }
            for (const element of this.careTeamMembersdetails) {
                let careMember: CareMemberInfo = element;
                this.addCareMemberAndEvents(careMember,false);
            }
        }
        finally {
            Loader.hideLoader("schedule-timeline");
        }
        this.reloadGrid();
    }
    public getColors(careMembersMap: Map<any, CareMemberInfo>){
        let i=0;
        this.myColors=[];
        for (const key of this.careMembersMap.entries()) {
            const memberId = key
            if(i%2 === 0){
                this.myColors.push({
                    start: this.startTimeOfTheDay,
                    end: this.endTimeOfTheDay,
                    recurring: {
                        repeat: 'daily',
                    },
                    resource: memberId,
                    background: '#f3f7ff',
                });
            }
            i++;
        }
    }

    private async loadAllEventsAppointementWizard(): Promise<void> {
        this.methodHit=true;
        this.events = [];
        this.updatedEvents = [];
        for (const resource of this.resources) {
            await this.loadCareMemberEvents(resource.id as number);
        }
    }

    private  loadAllEvents(): void {
        this.methodHit=true;
        this.events = [];
        this.updatedEvents = [];
        for (const resource of this.resources) {
              this.loadCareMemberEvents(resource.id as number);
        }
    }

    private async loadCareMemberEventsAppointmentWizard(careMemberId: number): Promise<void> {
        let resource: MbscResource = this.resources.find(r => r.id == careMemberId)!;
        resource.loading = true;

        try{
           let fromDate: Date = DateTime.from(this.calendarSelectedDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();
           let toDate: Date = DateTime.from(fromDate).addDays(1).getDate()!;

            let careMemberCalenderEvents = await this.calendarService.hSCalendarMemberSchedule(careMemberId, fromDate, toDate).toPromise();
            if(!resource.disabled && !careMemberCalenderEvents?.canAddEvents) {
                this.toastr.warning("The calendar is not available for '" + resource.name + "'");
                resource.disabled = !careMemberCalenderEvents?.canAddEvents;
            }

            for (const event of (careMemberCalenderEvents?.events ?? [])) {
                let fromDate = event.fromDate as string;
                let toDate = event.toDate as string;
                let startDate;
                let endDate;
                if (event.isAllDay) {
                    startDate = new Date(fromDate);
                    endDate = new Date(new Date(toDate).getTime() - 1);
                    startDate = DateTime.from(startDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();
                    endDate = DateTime.from(endDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();

                }
                else {
                    startDate = new Date(fromDate + "Z");
                    endDate = new Date(toDate + "Z");
                }
                                
                let calendarEvent = this.createEvent(event,careMemberId,startDate,endDate);

                if(calendarEvent.selectedAppointment){
                
                    this.updateOrAdd(this.updatedEvents, calendarEvent);
                }
            
                this.eventHelper.updateColor(calendarEvent);
                this.updateOrAdd(this.events, calendarEvent);
            }
            this.scheduleTimeline?.setEvents(this.events);
        } finally {
            resource.loading = false;
        }
    }

    private async loadCareMemberEvents(careMemberId: number): Promise<void> {
        let resource: MbscResource = this.resources.find(r => r.id == careMemberId)!;
        resource.loading = true;

        try{
           let fromDate: Date = DateTime.from(this.calendarSelectedDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();
           let toDate: Date = DateTime.from(fromDate).addDays(1).getDate()!;

            let careMemberCalenderEvents = await this.calendarService.hSCalendarMemberSchedule(careMemberId, fromDate, toDate).toPromise();
            if(!resource.disabled && !careMemberCalenderEvents?.canAddEvents) {
                this.toastr.warning("The calendar is not available for '" + resource.name + "'");
                resource.disabled = !careMemberCalenderEvents?.canAddEvents;
            }

            for (const event of (careMemberCalenderEvents?.events ?? [])) {
                let fromDate = event.fromDate as string;
                let toDate = event.toDate as string;
                let startDate;
                let endDate;
                if (event.isAllDay) {
                    startDate = new Date(fromDate);
                    endDate = new Date(new Date(toDate).getTime() - 1);
                    startDate = DateTime.from(startDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();
                    endDate = DateTime.from(endDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();

                }
                else {
                    startDate = new Date(fromDate + "Z");
                    endDate = new Date(toDate + "Z");
                }
                                
                let calendarEvent = this.createEvent(event,careMemberId,startDate,endDate);

                if(calendarEvent.selectedAppointment){
                
                    this.updateOrAdd(this.updatedEvents, calendarEvent);
                }
            
                this.eventHelper.updateColor(calendarEvent);
                this.updateOrAdd(this.events, calendarEvent);
            }
            this.scheduleTimeline?.setEvents(this.events);
        } finally {
            resource.loading = false;
        }
    }

    private createEvent(event : CalenderEventExt,careMemberId : number, startDate : Date, endDate : Date) : CalendarEvent{
        let calendarEvent : CalendarEvent = {};
                if(!event.isHSSchedule){
                    calendarEvent  = {
                        id: event.eventId,
                        patientId: event.patientId,
                        text: event.isHSSchedule ? this.apptModePipe.transform(event.careMemberSchedule?.modeId) : (event.title ?? "Not available"),
                        start: startDate,
                        end: endDate,
                        appointmentId: event.careMemberSchedule?.appointmentId,
                        modeId: event.careMemberSchedule?.modeId,
                        resource: careMemberId,
                        isHSSchedule: event.isHSSchedule,
                        timezoneOffset: this.timezoneOffset,
                        selectedAppointment: (this.appointmentId??0)>0 && this.appointmentId == event.careMemberSchedule?.appointmentId,
                        typeId: event.careMemberSchedule?.typeId,
                        schedulePreTime: event.careMemberSchedule?.schedulePreTime,
                        schedulePostTime: event.careMemberSchedule?.schedulePostTime,
                        cssClass : 'event-border'
                    };
                }
               else{
                calendarEvent = {
                    id: event.eventId,
                    patientId: event.patientId,
                    text: event.isHSSchedule ? this.apptModePipe.transform(event.careMemberSchedule?.modeId) : (event.title ?? "Not available"),
                    start: startDate,
                    end: endDate,
                    appointmentId: event.careMemberSchedule?.appointmentId,
                    modeId: event.careMemberSchedule?.modeId,
                    resource: careMemberId,
                    isHSSchedule: event.isHSSchedule,
                    timezoneOffset: this.timezoneOffset,
                    selectedAppointment: (this.appointmentId??0)>0 && this.appointmentId == event.careMemberSchedule?.appointmentId,
                    typeId: event.careMemberSchedule?.typeId,
                    schedulePreTime: event.careMemberSchedule?.schedulePreTime,
                    schedulePostTime: event.careMemberSchedule?.schedulePostTime
                };
               }
            return calendarEvent;
    }
    private async renderCalendarEventsAppointmentWizard(careMember: CareMemberInfo, distanceData: string, durationData?:string) {
        this.careMembersMap.set(careMember.id, careMember);
        const careMemberDetails = {
            id: careMember.id,
            name: careMember.name,
            description: careMember.type + distanceData + durationData,
        }
        this.resources.push(careMemberDetails);
        await this.loadCareMemberEventsAppointmentWizard(careMember.id);
        this.scheduleTimeline?.refresh();
    }
    private async renderCalendarEvents(careMember: CareMemberInfo, distanceData: string, memberIsCentralCareNurse?: boolean, durationData?:string) {
        this.careMembersMap.set(careMember.id, careMember);
        const careMemberDetails = {
            id: careMember.id,
            name: careMember.name,
            description: careMember.type + distanceData + durationData,
        }
        if (memberIsCentralCareNurse) {
            const data = [careMemberDetails, ...this.resources];
            this.resources = [...data];
        } else {
            this.resources.push(careMemberDetails);
        }
        
        // await this.loadCareMemberEvents(careMember.id);
        // this.scheduleTimeline?.refresh();
    }
    private hasCareMemberInCalendar(id: number) : boolean {
        return this.careMembersMap.has(id);
    }
    private async addCareMemberAndEvents(careMember: CareMemberInfo,IsAppointmentWizard:boolean, memberIsCentralCareNurse?: boolean,isInitialCall:boolean=false) {
        await this.addCareMember(careMember, IsAppointmentWizard, memberIsCentralCareNurse, isInitialCall);
        await this.loadCareMemberEvents(careMember.id);
        this.scheduleTimeline?.refresh();
    }
    private async addCareMemberAppointmentWizard(careMember: CareMemberInfo){
        let that = this;

        if (this.hasCareMemberInCalendar(careMember.id)) {
                return ;    
        }

        let distanceData = `<br/><div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05 me-1"><i class="fas fa-car fs-10"></i> N/A</div>`;
        let durationData = `<div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05"><i class="fas fa-clock fs-10"></i> N/A</div>`;

        if (that.patientModel?.address && careMember.address) {
            this.careTeamService.careTeamGetCareMemberDistance(that.patientModel.address, careMember.address).subscribe((distance) => {
                let distanceValue = distance && distance.distance != -1 ? `${Math.round(distance.distance)} miles` : "N/A";
                let durationValue = distance && distance.duration != -1 ? `${Math.round(distance.duration)} min` : "N/A";

                distanceData = distance ? `<br/><div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05 me-1"><i class="fas fa-car fs-10"></i> ${distanceValue}</div>` : distanceData;
                durationData = distance ? `<div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05"><i class="fas fa-clock fs-10"></i> ${durationValue}</div>` : durationData;

                const index = this.resources.findIndex((item) => item.id === careMember.id);
                this.resources[index].description = careMember.type + distanceData + durationData;

            });
        }
        await this.renderCalendarEventsAppointmentWizard(careMember, distanceData, durationData);
    }
    private async addCareMember(careMember: CareMemberInfo,IsAppointmentWizard:boolean, memberIsCentralCareNurse?: boolean,isInitialCall:boolean=false) {
        let that = this;

        if (this.hasCareMemberInCalendar(careMember.id)) {
            if(IsAppointmentWizard){
                return ;
            } 
            if (!isInitialCall) {
                this.showMessageIfCareMemberExist(careMember.name);
            }
            return;
        }

        let distanceData = `<br/><div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05 me-1"><i class="fas fa-car fs-10"></i> N/A</div>`;
        let durationData = `<div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05"><i class="fas fa-clock fs-10"></i> N/A</div>`;

        if (that.patientModel?.address && careMember.address) {
            this.careTeamService.careTeamGetCareMemberDistance(that.patientModel.address, careMember.address).subscribe((distance) => {
                let distanceValue = distance && distance.distance != -1 ? `${Math.round(distance.distance)} miles` : "N/A";
                let durationValue = distance && distance.duration != -1 ? `${Math.round(distance.duration)} min` : "N/A";

                distanceData = distance ? `<br/><div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05 me-1"><i class="fas fa-car fs-10"></i> ${distanceValue}</div>` : distanceData;
                durationData = distance ? `<div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05"><i class="fas fa-clock fs-10"></i> ${durationValue}</div>` : durationData;

                const index = this.resources.findIndex((item) => item.id === careMember.id);
                this.resources[index].description = careMember.type + distanceData + durationData;

            });
        }
        await this.renderCalendarEvents(careMember, distanceData, memberIsCentralCareNurse, durationData);
    }
    public openModelToRemoveCareMemberRow(careMember: CareMemberInfo): void {
        
        if (this.resources.length > 1) {
            this.removeCareMemberObj = careMember;
            const elemRef = $('#delete-care-member-modal');
            elemRef.modal({backdrop: 'static', keyboard: false});
            elemRef.modal('show');
        } else {
            if (this.deleteCareMemberInProgress) return;
            this.deleteCareMemberInProgress = true;
            setTimeout(()=> {
                this.deleteCareMemberInProgress = false;
            }, 5000);
            this.toastr.warning("At least one Care Team Member must be present on the scheduling grid. To remove this Care Team Member, you must first add a Care Team Member.");
        }        
    }
    public openModelCalanedar(): void {
        $("body").toggleClass("is-asidemodel");      
    }

    public openPopUpCalendar(careMember: CareMemberInfo): void {
        this.SelectedCareMemberId = careMember?.id;
        this.SelectedCareMemberName = careMember.name;
        const weekDays = DateTime.getWeekDates(DateTime.now());
        this.loadCareMemberEventsForPopupCal(careMember?.id,weekDays[0],weekDays[1]);
        $("body").toggleClass("is-asidemodel");      
    }

    public removeCareMember(): void {
        let that = this;
        let tempRemoveCareMemberObj = this.removeCareMemberObj;
        if (tempRemoveCareMemberObj) {
            const resourcesList = this.resources.filter((resourceItem) => {
                return resourceItem.id !== tempRemoveCareMemberObj?.id;
                })
            this.resources = [...resourcesList];
            this.updatedEvents = this.updatedEvents.filter((item) => (item.resource !== tempRemoveCareMemberObj?.id));
            this.events = this.events.filter((item) => (item.resource !== tempRemoveCareMemberObj?.id));
            this.careMembersMap.delete(tempRemoveCareMemberObj?.id);
            this.reloadGrid();
            this.toastr.success("Care Team Member removed successfully");
        }
    }
    public createAppointment(event: CalendarEvent, newSchedule: boolean): void {
        event.text = event.modeId;
        this.genericModalService.showModel<AppointmentPopupInput, AppointmentPopupOutput>(
            AppointmentPopupComponent, {
                event: event,
                patient: this.patientModel!,
                timezoneOffset:this.timezoneOffset,
                newSchedule: newSchedule,
            },
            (data: AppointmentPopupOutput) => {
                if(data.delete) {
                    let index1: number = this.updatedEvents.findIndex(event => event.id == data.event.id);
                    if(index1 >= 0) {
                        this.updatedEvents.splice(index1, 1);
                    }
                    let index2: number = this.events.findIndex(event => event.id == data.event.id);
                    if(index2 >= 0) {
                        this.events.splice(index2, 1);
                        this.scheduleTimeline?.setEvents(this.events);
                    }
                    return;
                }
                data.event.text = this.apptModePipe.transform(data.event.modeId!);
                this.eventHelper.updateColor(data.event);
                this.updateOrAdd(this.events, data.event);
                this.updateOrAdd(this.updatedEvents, data.event);

               
            }
        );
    }

    private updateOrAdd(events: CalendarEvent[], event: CalendarEvent) {
        let index: number = events.findIndex(evt => evt.id == event.id);
        if (index >= 0) {
            events[index] = event;
            this.scheduleTimeline?.updateEvent(event);
        } else {
            events.push(event);
            this.scheduleTimeline?.setEvents(this.events);
        }
    }

    public async saveChanges(form: any, isOverrideAppointment:boolean): Promise<void> {
        let mobile = this.contactInfo?.preferredContact?.replace(/-/g,'');
        if (form.invalid) {
            return;
        }
        if(this.updatedEvents.length == 0) {
            return; 
        }
        if(this.notificationMobile == mobile && this.notificationEmail == this.patientModel?.email){
            this.showError = true;
            this.showErrorEmail =true;
            this.showErrorPhone = true;
            return ;
        }
        if(this.notificationMobile == mobile){
            this.showError = true;
            this.showErrorPhone = true;
            return;
        }
        if(this.notificationEmail == this.patientModel?.email){
            this.showError = true;
            this.showErrorEmail =true;
            return;
        }
        const elemRef = $('#scheduleModal');
        elemRef.modal('hide');
        Loader.showLoader("scheduler-panel");
        let careMembers: CareMemberScheduleModel[] = [];
        try {
            let request: any = {};
            this.updatedEvents.forEach(event => {
                careMembers.push(
                    {
                        careMemberId: event?.resource as number,
                        modeId: event?.modeId!,
                        scheduleStartDateTime: event?.start as Date,
                        scheduleEndDateTime: event?.end as Date,
                        scheduleDuration: ((event?.end as Date).getTime() - (event?.start as Date).getTime()) / DateTime.millisInMinute,
                        schedulePreTime: event?.schedulePreTime!,
                        schedulePostTime: event?.schedulePostTime!,
                        preferredLanguageCode: event?.preferredLanguageCode!,
                        typeId: event?.typeId!
                    }
                );
                
            });

            request = <CreateAppointmentRequest>{
                draftAppointmentId: this.draftAppointmentId,
                patientId: this.patientModel?.id!,
                address: this.address,
                addressType: this.addressType,
                taskIds: this.selectedTasks.map(task => task.task),
                scheduleTimeZoneId: this.timezone,
                note: this.updatedEvents[0].notes,
                careMembers: careMembers,
                mobileNo: this.contactInfo?.preferredContact,
                textOptOutStatus: this.contactInfo?.isTextOptOut,
                callOptOutStatus: this.contactInfo?.isCallOptOut,
                notificationEmailId: this.notificationEmail,
                notificationMobileNo: this.notificationMobile,
                email: this.patientModel?.email,
                id: 0, typeId: 0,
                scheduleDateTime: new Date(),
                isOverrideAppointment: isOverrideAppointment,
                elasticId: this.bestTimeElasticId
            };
            if(this.appointmentDetails) {
                request.id = this.appointmentDetails.id;
                let response = await this.appointmentService.appointmentUpdateAppointments(request).toPromise();

                if (response?.scheduleResponses && response.scheduleResponses.length > 0) {
                    this.scheduleResponses = response.scheduleResponses;
                    Loader.hideLoader("scheduler-panel");
                    const elemRef = $('#schedule-conflict-response-modal');
                    elemRef.modal({backdrop: 'static', keyboard: false});
                    elemRef.modal('show');
                }
                else {
                    this.toastr.success("Appointment updated successfully.");
                    let that = this;
                    careMembers.forEach(function (item) {
                        that.calendarService.hSCalendarUpdateByCareMemberCalenderEventsDelta(item.careMemberId!).toPromise();
                    });
                    this.router.navigateByUrl("/appointments/" + this.appointmentDetails.id);
                }
               
            } else {
                let appt = await this.appointmentService.appointmentCreateAppointments(request).toPromise();

                if (appt?.scheduleResponses && appt.scheduleResponses.length > 0) {
                    this.scheduleResponses = appt.scheduleResponses;
                    Loader.hideLoader("scheduler-panel");
                    const elemRef = $('#schedule-conflict-response-modal');
                    elemRef.modal({backdrop: 'static', keyboard: false});
                    elemRef.modal('show');
                }
                else {
                    let that = this;
                    if(appt?.appointmentId != 0){
                    this.toastr.success("Appointment booked successfully.");
                    careMembers.forEach(function (item) {
                        that.calendarService.hSCalendarUpdateByCareMemberCalenderEventsDelta(item.careMemberId!).toPromise();
                    });
                    this.router.navigateByUrl("/appointments/" + appt?.appointmentId);
                    this.logging.trackEvent('Appointment Created Successfully', this.logging.createCustomLog('User Clicked On Confirm','Sucessfully Created Appointment','INFO'));
                }
                else{
                    this.toastr.error("Failed to book appointment. Please try again.");
                    this.logging.trackEvent('Appointment Creation Failed', this.logging.createCustomLog('User Clicked On Confirm','Failed to Create Appointment','ERROR'));
                }
                }
                
            }
        }
        finally {
            Loader.hideLoader("scheduler-panel");
            
        }
    }

    public async clearChanges(): Promise<void> {
        this.scheduleTimeline?.setEvents(this.events);
        this.loadAllEvents();

        this.draftAppointmentId = 0;
        this.meetingLink = null;
    }
    private showMessageIfCareMemberExist(name?: string): void{
        this.toastr.warning(`${name} is already added to calendar`);
    }
    public launchAddCareMember(): void {
        let that = this;
        this.genericModalService.showModel(
            AddCareMemberComponent, that.patientModel, 
            (careMember: CareMemberInfo) => {
                this.addCareMemberAndEvents(careMember,false);
                this.reloadGrid();
            },
        );
    }

    private reloadGrid(): void {
        setTimeout(() => {
            this.schedulerVisible = false;
            setTimeout(() => {
                this.schedulerVisible = true;
            }, 0);
        }, 0);
        this.getColors(this.careMembersMap);
    }

    public timezoneChanged(): void {
        const timezoneName = this.timezones.filter((entry) => entry.code === this.timezone)
        this.scheduleService.sendTimezone((timezoneName.length > 0 && timezoneName[0].name) ? timezoneName[0].name : 'Eastern Standard Time');
        this.scheduleService.sendTimezoneCode(this.timezone!);
        /** Update Timezone Offset */
        let date = new Date();
        let tzDate = new Date(date.toLocaleString('en-US', { timeZone: this.timezone }));
        this.timezoneOffset = date.getTime() - tzDate.getTime();
        this.loadAllEvents();
    }

    public closeModal(): void {
        this.loadAllEvents();
    }

    public confirmModal(form :any): void {
        this.saveChanges(form, true);
    }

    public showHideNPSearchLink():void{
        this.nPSearchLink = this.patientModel?.npEligibilityStatus?.toLocaleLowerCase() == 'yes';
    }

    public showVideoLink(): void {
        let action = this.appointmentDetails ? 'Update' : 'Book'
        
        this.logging.trackEvent('Schedule Grid : User Clicked On ' + action + ' Appointment',this.logging.createCustomLog('Clicked on '+action+' Appointment','Opening Confirmation modal', 'INFO'));
        this.testVideoLinkRequired = this.updatedEvents.some(event => event.modeId == 'telehealth');
    }

    public getMidNightDate(date: Date): Date {
        let tzDate = new Date(date.toLocaleString('en-US', { timeZone: this.timezone }));
        tzDate.setHours(0,0,0,0);
        return tzDate;
    }

    public ngOnDestroy(): void {
    }

    public initTImezoneOffset(): void {
    }

    public roundTimeQuarterHour(timeinMS: number):Date {
        var timeToReturn = new Date(timeinMS);
    
        timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
        timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
        return timeToReturn;
    }

    public async draftChanges(): Promise<void> {
        if (this.updatedEvents.length == 0) {
            return;
        }
        if (this.meetingLink) {
            window.open(this.meetingLink, "_blank");
            return;
        }
        Loader.showLoader("scheduler-panel");
        try {
            let request: any = {};
            let careMembers: CareMemberScheduleModel[] = [];
            this.updatedEvents.forEach(event => {
                careMembers.push(
                    {

                        careMemberId: event?.resource as number,
                        modeId: event?.modeId!,
                        scheduleStartDateTime: event?.start as Date,
                        scheduleEndDateTime: event?.end as Date,
                        scheduleDuration: ((event?.end as Date).getTime() - (event?.start as Date).getTime()) / DateTime.millisInMinute,
                        schedulePreTime: event?.schedulePreTime!,
                        schedulePostTime: event?.schedulePostTime!,
                        preferredLanguageCode: event?.preferredLanguageCode!,
                        typeId: event?.typeId!
                    }
                );

            });



            request = <CreateAppointmentRequest>{
                patientId: this.patientModel?.id!,
                address: this.address,
                addressType: this.addressType,
                taskIds: this.selectedTasks.map(task => task.task),
                scheduleTimeZoneId: this.timezone,
                note: this.updatedEvents[0].notes,
                careMembers: careMembers,
                mobileNo: this.contactInfo?.preferredContact,
                textOptOutStatus: this.contactInfo?.isTextOptOut,
                callOptOutStatus: this.contactInfo?.isCallOptOut,
                notificationEmailId: this.notificationEmail,
                notificationMobileNo: this.notificationMobile,
                email: this.patientModel?.email,
                id: 0, typeId: 0,
                scheduleDateTime: new Date(),
                isOverrideAppointment:false
            };
            let appt = await this.appointmentService.appointmentDraftAppointments(request, false).toPromise();
            if (appt?.item1) {
                this.draftAppointmentId = appt.item2;
                let meetingLink = appt.item1+'&name='+this.userName.replace(' ','_');
                this.meetingLink = meetingLink;
                window.open(this.meetingLink, "_blank");
            }
        } finally {
            Loader.hideLoader("scheduler-panel");
        }
    }

    private loadCareMemberEventsForPopupCal(careMemberId : number, startDate: Date, endDate: Date): void {
        try
        {
            this.isMonthCreateAppointment = false;
            this.popupCalEventsLoading = true;
            this.calendarService.hSCalendarMemberSchedule (careMemberId, startDate, endDate).subscribe((response: CareMemberCalenderEvents) => {
                const allEvents: MbscCalendarEvent[] = [];
                const events: Array<CalendarEvent> = response.events? response.events : [];
                for (const event of events)
                {
                    let startDate = event.fromDate ? new Date(event.fromDate + 'Z') : undefined;
                    let endDate = event.toDate ? new Date(event.toDate + 'Z') : undefined;
                    // if (event.allDay) {
                    //     startDate = event.fromDate ? new Date(event.fromDate) : undefined;
                    //     endDate = event.toDate ? new Date(new Date(event.toDate).getTime() - 1) : undefined;
                    // }
                    if (event.isAllDay) {
                        startDate = new Date(event.fromDate);
                        endDate = new Date(new Date(event.toDate).getTime() - 1);
                        startDate = DateTime.from(startDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();
                        endDate = DateTime.from(endDate.getTime()).addMinutes(-DateTime.getLocalOffset(this.timezone!)).getDate();
    
                    }
                    else {
                        startDate = new Date(event.fromDate + "Z");
                        endDate = new Date(event.toDate + "Z");
                    }

                    allEvents.push({
                    careMemberSchedule: event.careMemberSchedule,
                    isHSSchedule: event.isHSSchedule,
                    patientId: event.patientId,
                    showAs: event.showAs,
                    start: startDate,
                    end: endDate,
                    title: event.title ? event.title : '',
                    color: this.scheduleColor(event),
                    id: event.eventId,
                    text: event.isHSSchedule ? this.apptModePipe.transform(event.careMemberSchedule?.modeId) : (event.title ?? "Not available"),
                    appointmentId: event.careMemberSchedule?.appointmentId,
                    modeId: event.careMemberSchedule?.modeId,
                    resource: careMemberId,
                    timezoneOffset: this.timezoneOffset,
                    selectedAppointment: (this.appointmentId??0)>0 && this.appointmentId == event.careMemberSchedule?.appointmentId,
                    typeId: event.careMemberSchedule?.typeId,
                    schedulePreTime: event.careMemberSchedule?.schedulePreTime,
                    schedulePostTime: event.careMemberSchedule?.schedulePostTime
                    });
                }
                this.zone.run(() => {
                    this.eventsPopupCal = [...allEvents];
                    this.popupCalEventsLoading = false;
                });
            });
        }
        finally
        {
            
        }
      }

      private scheduleColor(event: CalendarEvent): string {
        if(!event.isHSSchedule) {
          return CommonConstants.LIGHTGRAY;
        }
        const modeId = event.careMemberSchedule? String(event.careMemberSchedule.modeId).toLowerCase(): '';
        if(modeId === CommonConstants.IN_HOME) {
          return CommonConstants.NEW_IN_HOME_COLOR;
        } else if (modeId === CommonConstants.TELEHEALTH) {
          return CommonConstants.NEW_TELEHEALTH_COLOR;
        } else if (modeId === CommonConstants.TELEPHONE) {
          return CommonConstants.NEW_TELEPHONE_COLOR;
        } else if (modeId === CommonConstants.IN_CLINIC) {
            return CommonConstants.NEW_IN_CLINIC_COLOR;
        }
        return ''
      }

      public CreateMonthCalAppointment() : void
      {
        let event: CalendarEvent = {                                                                                                          
            id: Date.now(),
            start: DateTime.from(this.selectedMonthDate).addHours(8).getDate(),
            patientId: this.patientModel?.id,
            resource: this.SelectedCareMemberId,
            text: "New appoinement",
            isHSSchedule: true,
            timezoneOffset: this.timezoneOffset 
        };
        this.eventHelper.updateColor(event);
        $("body").toggleClass("is-asidemodel");
        this.calendarSelectedDate = this.selectedMonthDate;
        this.reloadGrid(); 
        this.createAppointment(event, true);
      }

      public TransformDate(value : Date) : string
      {
        return moment(value).format("YYYY-MM-DD");
      }

    changeView(): void {
        this.isMonthCreateAppointment = false;
        setTimeout(() => {
          switch (this.popupCalView) {
            case 'year':
                this.calView = {
                    calendar: { type: 'year'
                 }
                };
                break;
            case 'month':
                this.calView = {
                    calendar: { type: 'month' },
                };
                break;
            case 'week':
                this.calView = {
                    schedule: { type: 'week',
                    startTime: this.startTimeOfTheDayModal,
                    endTime: this.endTimeOfTheDayModal
                }
                };
                break;
            case 'day':
                this.calView = {
                    schedule: { type: 'day',
                    startTime: this.startTimeOfTheDayModal,
                    endTime: this.endTimeOfTheDayModal }
                };
                break;
            case 'agenda':
                this.calView = {
                    calendar: { type: 'week' },
                    agenda: { type: 'week' }
                };
                break;
          }
        });
    }

    public submitBestTimeEvent($event: CareMemberInfo[]): void {
        this.addBestTimeEvent($event);
    }

    public submitElasticEvent($event: string): void {
        this.bestTimeElasticId = $event;
        console.log(this.bestTimeElasticId);
    }

    public addBestTimeEvent(careNurse: CareMemberInfo[]) {
        if (careNurse && careNurse.length > 0) {
            for (var i = 0; i < careNurse.length; i++) {
                let careMember: CareMemberInfo = careNurse[i];
                this.addCareMemberAndEvents(careMember,false, true);
            }
            const selectedDate: Date = new Date(careNurse?.[0].scheduleStartDate)

            /** Convert time to selected timezone and select calendar date */
            this.calendarSelectedDate = DateTime.from(selectedDate).addMinutes(DateTime.getLocalOffset(this.timezone!)).startOfTheDay().getDate();
            this.reloadGrid();
        }
    }

    public dateTimeChanged() {
        this.calendarSelectedDate = this.selectedMonth;
        if(this.firstTimeLoad){
            this.loadAllEvents();
        }
        else{
            this.loadAllEventsAppointementWizard();
        }
            
    }

    public clicked() {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            if (!this.methodHit) {
            console.log("executing again");
            this.loadAllEvents();
            this.methodHit = true;
        }
    }, 5000);
        this.methodHit=false;
    }
    public formTouched(){
        this.showError = false;
        this.showErrorEmail = false;
        this.showErrorPhone = false;
    }
}
